@charset "UTF-8";

@for $i from 1 through 5 {
  .space-#{$i} {
    display: block;
    height: #{px($i*$box-spacing)};
  }
}

@include lView(){
  :not(.column-50)>.textImage{
    .text-image-background:not(.full-width),
    .text-image-top:not(.full-width){
      .texts{
        width: 60%;
      }
    }
  }
}

.textImage{
  margin-bottom: px($box-spacing);
  position: relative;

  @include sView(){
    .text-image-left,
    .text-image-right{
      display: block !important;
      width: 100% !important;
      margin-left: 0 !important;
    }
    .image {
      &.image-left,
      &.image-right {
        display: block !important;
        width: 100% !important;
        margin: 0 !important;
      }
      &.image-left{
        video,
        img{
          margin-left: 0 !important;
        }
      }
    }
  }
  @include smView(){
    .downloads {
      .popup {
        width: px($mobile-popup-width) !important;
      }
    }
    .image {
      img {
        object-fit: contain;
      }
    }
  }

  .text-image-left,
  .text-image-right{
    display: inline-block;
    vertical-align: top;
    width: calc(50% - #{px($base-padding + 4)});
  }
  .text-image-right{
    margin-left: px($box-spacing);
  }
  .text-image-background{
    position: absolute;
    top: 0;
  }

  .texts{
    padding: px($box-spacing * 2) px($box-spacing) px($box-spacing);
    .text{
      margin-top: 19px;

      ul, ol {
        line-height: 1.25;
        margin-bottom: .8em;

        li {
          padding-left: px(5);
          //margin-bottom: 0.5em;
        }
      }

      ul {
        padding-left: px(21);

        li {
          list-style-type: disc;

          &::marker {
            font-size: .9em;
          }
        }
      }

      ol {
        padding-left: px(21);
        li {
          list-style-type: decimal;
        }
      }
    }
  }
  .image {
    position: relative;

    //play btn for vids
    &>svg{
      position: absolute;
      top: 10px;
      left: 10px;
      width: 23px;
      pointer-events: none;
    }

    video,
    img {
      cursor: pointer;
      display: block;
      max-width: 100%;
      &.with-shadow{
        box-shadow: $box-shadow;
      }
    }
    img {
      max-height: 80vh;
    }

    video{
      object-fit: contain;
      object-position: left;
    }

    &.image-top video,
    &.image-top img {
      cursor: default;
    }

    &.image-left,
    &.image-right {
      display: inline-block;
      margin: 0 px($box-spacing);
      width: calc( 50% - #{px($box-spacing * 2)} );
    }
    &.image-left{
      //width: calc( 50% - #{px($box-spacing * 2)} );
      video,
      img{
        margin-left: auto;
      }
    }
  }
  .downloads{
    z-index: 100;
    position: relative;
    top: -21px;
    display: inline-block;
    padding-left: px($box-spacing);

    &:hover{
      .popup{
        visibility: visible;
        opacity: 1;
      }
    }

    .popup-trigger{
      z-index: 11;
      position: relative;
      width: px($icon-size);
      height: px($icon-size);
    }

    .popup {
      z-index: 10;
      position: absolute;
      top: -17px;
      left: 0;
      box-shadow: $box-shadow;
      background-color: $grey-light-bg;
      padding: 15px px($box-spacing) px($box-spacing * 2);
      width: px($popup-width);
      visibility: hidden;
      opacity: 0;
      transition: opacity $anim-speed, visibility $anim-speed;
      transition-timing-function: ease-in-out;

      h2{ margin-top: 6px; }
      h2,
      .subtitle{
        margin-left: 32px;
      }

      .dl-list{
        margin-top: 60px;
        border-top: 1px solid $grey-light;

        li{
          border-bottom: 1px solid $grey-light;
          padding: px($box-spacing) 0;
          font-size: 11px;
          font-family: $secondFontFace;
          line-height: 1.1;
          &.header{
            font-weight: 700;
          }

          a{
            position: relative;
            display: block;
            text-transform: uppercase;
            &:hover {
              font-weight: 700;
            }

            .download-title{
              width: 65%;
              padding-right: px($box-spacing);
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 0.8;
            }
            .download-icon{
              position: absolute;
              right: 0;
              top: -6px;
              display: inline-block;
              width: px($icon-size);
              height: px($icon-size);
            }
          }
        }
      }
    }
  }
}
