@charset "UTF-8";

* {
  box-sizing: border-box;
}

b,
strong {
  font-weight: 700;
}

.gray {
  color: $grey;
}

h1,h2,h3,h4,
.subtitle {
  font-family: $secondFontFace;
}

h1 {
  line-height: .9;
  font-size: px(26);
}

h2 {
  line-height: .9;
  font-size: px(23);
}

h3 {
  line-height: 0.9;
  font-size: px(22);
}

h4 {
  font-size: px(16);
  font-weight: bold;
  &.subtitle{
    font-weight: normal;
  }
}

h5 {
  font-size: px(13);
}

a {
  text-decoration: none;
  color: $black;
}

p {
  margin-bottom: 1em;
}

body {
  width: 100%;
  height: 100%;
  font-family: $mainFontFace;
  overflow-x: hidden;
  //firefox grey fix
  background-color: $white;
  #main {
    //firefox grey fix
    background-color: $white;
  }
}

//slide all components in on scroll
#main {
  & > section {
    transition: $anim-speed transform ease, $anim-speed opacity ease;
    &:not(.slided) {
      transform: translateY(150px);
      opacity: 0;
    }
  }
}

@keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes fadeout {
  from {opacity: 1;}
  to {opacity: 0;}
}
.fadeIn {animation: $anim-speed fadein}
.fadeOut {animation: $anim-speed fadeout}

#imgPopup {
  z-index: 8000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);

  #imgContainer{
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 2560px;
    max-height: 2560px;
    width: 80%;
    height: auto;
    margin: auto;

    video,
    img {
      max-width: 100%;
      max-height: 70vh;
    }

    #imgTextWrap{
      text-align: left;
      margin: px($box-spacing);
      color: $white;
      max-width: 70%;

      #imgTitle{
        font-weight: 700;
        font-size: px($h2-font-size);
        line-height: 1;
      }
      #imgSubtitle{
        font-family: $secondFontFace;
        margin-bottom: 0 !important;
      }
      #imgText{
        font-family: $mainFontFace;
        margin-top: px($base-padding);
      }
    }
  }

  .no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}


@include sView() {
  #main > * {
    position: static !important;
    transform: none !important;
  }

  .arrow {
    display: none;
  }
  .slide{
    height: auto!important;
  }
}
