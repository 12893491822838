@charset "UTF-8";


#main > .media-wrapper {
  position: relative !important;
  overflow: hidden;

  .media-over {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;

    img {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
      transition: opacity 200ms;
    }

    button {
      display: block;
      position: absolute;
      border-radius: 50%;
      width: px(74);
      height: px(74);

      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      background-color: transparent;
      transition: background-color 200ms ease-in-out, opacity 200ms;

      &:hover {
        background-color: rgba(255,255,255,0.8);
      }
    }

    button.play {
      pointer-events: auto;
      border: 3px solid $black;

      span {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        &:before, &:after {
          content: '';
          position: absolute;
          width: 50%;
          right: 25%;
          height: 0;
          border-bottom: 3px solid $black;
          top: 50%;
          transform-origin: right center;

        }

        &:before {
          transform: translateY(-50%) rotateZ(-35deg);
        }

        &:after {
          transform: translateY(-50%) rotateZ(35deg);
        }
      }
    }

    button.sound {
      opacity: 0;
      pointer-events: none;
      border-radius: 50%;

      g.x {
        transition: opacity 200ms;
      }

      &.mute {
        g.x {
          opacity: 0;
        }
      }
    }
  }

  &.playing {
    img {
      opacity: 0
    }

    button.play {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.autoplay {
    button.sound {
      opacity: 1;
      pointer-events: auto;
    }

    &.unmuted {
      button.sound {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}