@charset "UTF-8";

#side-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: px($menu-width);
  padding: px($base-padding);
  background-color: $white;

  height: px($topbar-height);

  #menuToggle>input {display: none;}
  @include sView(){
    #mobileMenu {
      width: 100% !important;
    }
  }
  @include smView(){
    transform: translateZ(900px);

    height: px($mobile-topbar-height);

    width: 100%;
    z-index: 200;
    right: 0;
    padding: px($box-spacing);
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-right: none;

    #logo{
      height: 40px;
      margin-bottom: 0 !important;
    }

    #mobileMenu {
      top: px($mobile-topbar-height) !important;
      height: calc(100vh - #{px($mobile-topbar-height)}) !important;
      padding: px($box-spacing) !important;
      -webkit-font-smoothing: antialiased;
      transform-origin: 0 0;
      transform: translate(-100%,0);
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }

    #menuToggle {
      z-index: 1;
      position: relative;
      display: block;
      height: 24px;
      margin-top: 8px;
      -webkit-user-select: none;
      user-select: none;

      &>input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        left: -5px;
        cursor: pointer;
        opacity: 0;
        z-index: 2; // over the hamburger
        -webkit-touch-callout: none;

        //mobile nav active (burger in X state)
        &:checked {
          & ~ #mobileMenu {
            transform: none; //show mobileMenu
          }
          & ~ .bar1 {
            opacity: 1;
            transform: rotate(45deg) translate(6px, -8px);
          }
          & ~ .bar2 {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
          }
          & ~ .bar3 {
            opacity: 1;
            transform: rotate(-45deg) translate(5px, 9px);
          }
        }
      }

      &>.burger-bar {
        display: block;
        width: 33px;
        height: 2px;
        margin-bottom: 7px;
        position: relative;
        background: $black;
        z-index: 1;
        transform-origin: 4px 0;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
          background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
          opacity 0.55s ease;

        &.bar1 {
          transform-origin: 0% 0%;
        }
        &.bar3 {
          transform-origin: 0% 100%;
        }
      }
    }
  }
  @include lView(){
    @include canHover(){
      #mobileMenu {
        opacity: 0.5;
        transition: opacity 0.5s;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  #logo {
    margin-bottom: px($base-padding);
    width: 170px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  #siteTitle h4,
  .navigation{
    font-size: 77%;
  }

  #siteTitle {
    margin-bottom: 27px;
    .subtitle{
      line-height: 1.1;
      text-transform: uppercase;
    }
  }

  #mobileMenu {
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    position: fixed;
    top: px($topbar-height);
    left: 0;
    bottom: 0;
    height: calc(100vh - #{px($topbar-height)});
    width: px($menu-width);
    padding: 0 px($base-padding) px($base-padding);
    background: $white;
  }

  #menuInfo {
    color: $grey;
    font-size: 12px;

    position: relative;
    margin-top: auto;

    #menuContact {
      color: $black;
    }

    p {
      margin-bottom: 16px;
    }
    a {
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
