@charset "UTF-8";

@font-face {
  font-family: 'Montserrat';
  src: url(../fonts/Montserrat-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 300
}
@font-face {
  font-family: 'Montserrat';
  src: url(../fonts/Montserrat-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700
}

@font-face {
  font-family: 'SourceSansPro';
  src: url(../fonts/SourceSansPro-Light.otf) format('opentype');
  font-style: normal;
  font-weight: 300
}
@font-face {
  font-family: 'SourceSansPro';
  src: url(../fonts/SourceSansPro-Bold.otf) format('opentype');
  font-style: normal;
  font-weight: 700
}
