@charset "UTF-8";

@mixin flexBoxes($justify:center, $align:center, $direction:row, $wrap:nowrap) {
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-direction: $direction;
  -webkit-flex-direction: $direction;
  flex-direction: $direction;

  -ms-flex-wrap: $wrap;
  -webkit-flex-wrap: $wrap;
  flex-wrap: $wrap;

  -webkit-align-items: $align;
  align-items: $align;

  -webkit-justify-content: $justify;
  justify-content: $justify;
}

//media queries
$small: "screen and (max-width: #{px(map_get($screen, "small"))})";
$medium: "screen and (min-width: #{px(map_get($screen, "small") + 1)}) and (max-width: #{px(map_get($screen, "medium"))})";
$smallToMedium: "screen and (max-width: #{px(map_get($screen, "medium"))})";
$large: "screen and (min-width: #{px(map_get($screen, "medium") + 1)})";
$mediumToLarge: "screen and (min-width: #{px(map_get($screen, "medium") + 1)})";
$xlarge: "screen and (min-width: #{px(map_get($screen, "large") + 1)})";

@mixin sView() {
  @media #{$small} {
    @content;
  }
}

@mixin mView() {
  @media #{$medium} {
    @content;
  };
}

@mixin smView() {
  @media #{$smallToMedium} {
    @content;
  };
}

@mixin lView() {
  @media #{$large} {
    @content;
  };
}

@mixin mlView() {
  @media #{$mediumToLarge} {
    @content;
  };
}

@mixin xlView() {
  @media #{$xlarge} {
    @content;
  };
}

@mixin minView($minVal) {
  @media screen and (min-width: #{px($minVal)}) {
    @content;
  }
}

@mixin maxView($maxVal) {
  @media screen and (max-width: #{px($maxVal)}) {
    @content;
  }
}

@mixin underContentWidthView($px:0) {
  @include maxView($content-width + $px) {
    @content;
  }
}

@mixin overContentWidthView($px:0) {
  @include minView($content-width + $px) {
    @content;
  }
}

@mixin noJs($selector) {
  body.no-js #{$selector} {
    @content;
  }
}

@mixin js($selector) {
  body:not(.no-js) #{$selector} {
    @content;
  }
}

@mixin notTouch($selector) {
  body:not(.touch) #{$selector} {
    @content;
  }
}

@mixin touch($selector) {
  body.touch #{$selector} {
    @content;
  }
}

@mixin canHover {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}
