@charset "UTF-8";
@import '~highlight.js/styles/default.min.css';
//@import '~highlightjs-copy/dist/highlightjs-copy.min.css';

.code {
  .hljs-copy-button {
    background-color: $black !important;
    &[data-copied="true"] {
      padding: 0 10px;
    }
  }
  .tab-wrap {
    border-radius: 6px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    list-style: none;
  }
  .tab {
    display: none;
  }
  .tab__content {
    background-color: transparent;
    position: absolute;
    width: 100%;
    z-index: -1;
    opacity: 0;
    left: 0;
    transform: translateY(-3px);
    border-radius: 6px;
    transition: 0.5s opacity ease-in, 0.2s transform ease;
  }

  @for $i from 1 through 5 {
    .tab:checked:nth-of-type(#{$i}) ~ .tab__content:nth-of-type(#{$i}) {
      opacity: 1;
      position: relative;
      transform: translateY(0px);
      top: 0;
      z-index: 100;
      text-shadow: 0 0 0;
    }
  }

  .tab:first-of-type:not(:last-of-type) + label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .tab:not(:first-of-type):not(:last-of-type) + label { border-radius: 0; }
  .tab:last-of-type:not(:first-of-type) + label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .tab:checked + label {
    background-color: #fff;
    box-shadow: 0 -1px 0 #fff inset;
    cursor: default;
  }
  .tab:checked + label:hover {
    box-shadow: 0 -1px 0 #fff inset;
    background-color: #fff;
  }
  .tab + label {
    width: 100%;
    box-shadow: 0 -1px 0 #eee inset;
    border-radius: 6px 6px 0 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: #333;
    flex-grow: 3;
    text-align: center;
    background-color: #f2f2f2;
    user-select: none;
    text-align: center;
    transition: 0.3s background-color ease, 0.3s box-shadow ease;
    height: 50px;
    box-sizing: border-box;
    padding: 15px;
  }
  .tab + label:hover {
    background-color: #f9f9f9;
    box-shadow: 0 1px 0 #f4f4f4 inset;
  }

  @include mlView() {
    .tab + label { width: auto; }
  }
}



