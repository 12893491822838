@charset "UTF-8";

//WIDTHS & HEIGHTS
$content-width: 1440;
$menu-width: 320;
$popup-width: 405;
$icon-size: 23;
$topbar-height: 97;
$mobile-topbar-height: 65;
$mobile-popup-width: 320;

//Breakpoints
$screen: (
  "small": 749,
  "medium": 1024,
  "large": 1440
);

//SPACES
$base-padding: 26;
$box-spacing: 13;
$menu-spacing: 37;

//FONTS
$mainFontFace: "SourceSansPro", sans-serif;
$secondFontFace: "Montserrat", sans-serif;

$h2-font-size: 16;

//COLORS
$white: #ffffff;
$black: #12120d;
$grey: #727272;
$grey-light: #d9d9d9;
$grey-light-bg: #f2f2f2;
$blue-light: #00A9E0;
$blue-dark: #002a6f;

//SHADOWS
$box-shadow: 0 0 13px rgba(0, 0, 0, 0.2);
$filter-shadow: drop-shadow(0 0 5px rgba(0, 0, 0, 0.7));

//ANIMATIONS
$anim-speed: .2s;
$stdSpeed: 300;
$stdBCurve: cubic-bezier(.27,.72,.21,.91);
