@charset "UTF-8";

.slider {
  position: relative;
  margin: 0 auto -15px;

  @include smView(){
    .arrow,
    .slide-dots {
      transform: scale(0.6) !important;
    }
    .slide-dots {
      a {
        padding: px($box-spacing) !important;
      }
    }
  }

  .slide-dots{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 0.7;
    font-size: 20px;
    padding-bottom: px($base-padding);
    text-align: center;
    text-shadow: 0 0 3px $white;
    a{
      padding: 4px;
      color: $blue-light !important;
      &.active {
        color: white !important;
      }
      &:hover{
        text-decoration: none !important;
      }
    }
  }

  .arrow {
    z-index: 2;
    cursor: pointer;
    height: 2rem;
    width: 1.2rem;
    position: absolute;
    top: 50%;
    margin-top: -2rem;
    background-repeat: no-repeat;
    transition: padding 0.3s;

    svg {
      filter: $filter-shadow;
      path{
        box-shadow: $box-shadow;
        stroke-width: 2px;
        fill: none;
        stroke: $white;
      }
    }
    &:hover{
      padding: 0.2rem;
    }
  }
  #arrow-left {
    left: 0;
    margin-left: 1rem;
  }
  #arrow-right {
    right: 0;
    margin-right: 1rem;
  }


  .slider-area{
    display: flex;
    overflow-x: auto;
    overflow-y: hidden; //Safari fix for white square
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    //hide scrollbars:
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar { width: 0; } //Safari, Chrome...
    scrollbar-width: none; //Firefox
    -ms-overflow-style: none; //IE11

    .slide {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      scroll-snap-align: start;
      flex-shrink: 0;
      width: 100%;
      height: 80vh;
      transform-origin: center center;
      transform: scale(1);
      transition: transform 0.5s;
      position: relative;
      margin: 0 1px;
    }
  }
}



