@charset "UTF-8";

$sukoa_mainFontFace: "Montserrat";
$sukoa_secondFontFace: "Montserrat";
$sukoa_blue: #00e1ff;
$sukoa_bg: $sukoa_blue;

body#standard-sukoa {
  font-family: $sukoa_mainFontFace;
  background-color: $sukoa_bg;

  #main {
    background-color: $sukoa_bg;
  }

  #logo {
    height: 39px;
  }

  h1,h2,h3,h4,
  .subtitle {
    font-family: $sukoa_mainFontFace;
  }

  .loginform input,
  .login {
    font-family: $sukoa_mainFontFace;
  }

  .navigation {
    font-family: $sukoa_mainFontFace;
  }
  #side-menu #mobileMenu {
    opacity: 1;
  }
}

#imgPopup.standard-sukoa {
  #imgContainer{
    #imgTextWrap {
      #imgSubtitle{
        font-family: $sukoa_secondFontFace;
      }
      #imgText{
        font-family: $sukoa_mainFontFace;
      }
    }
  }
}
