@charset "UTF-8";

#main {
  margin-left: px($menu-width);

  @include smView(){
    margin-left: 0;
    margin-top: px($mobile-topbar-height);
  }

  h2 {
    font-size: px($h2-font-size);
    font-weight: 700;
    line-height: 1;
  }

  &.show-gradient {
    min-height: 100vh;
    background-image: linear-gradient(to bottom, $blue-light , $blue-dark);
  }
}
