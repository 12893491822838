@charset "UTF-8";
@import '~highlight.js/styles/default.min.css';
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

sub, sup {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 57%;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button, [type=submit], [role=button] {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat-Light.ttf) format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat-Bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "SourceSansPro";
  src: url(../fonts/SourceSansPro-Light.otf) format("opentype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "SourceSansPro";
  src: url(../fonts/SourceSansPro-Bold.otf) format("opentype");
  font-style: normal;
  font-weight: 700;
}
* {
  box-sizing: border-box;
}

b,
strong {
  font-weight: 700;
}

.gray {
  color: #727272;
}

h1, h2, h3, h4,
.subtitle {
  font-family: "Montserrat", sans-serif;
}

h1 {
  line-height: 0.9;
  font-size: 26px;
}

h2 {
  line-height: 0.9;
  font-size: 23px;
}

h3 {
  line-height: 0.9;
  font-size: 22px;
}

h4 {
  font-size: 16px;
  font-weight: bold;
}
h4.subtitle {
  font-weight: normal;
}

h5 {
  font-size: 13px;
}

a {
  text-decoration: none;
  color: #12120d;
}

p {
  margin-bottom: 1em;
}

body {
  width: 100%;
  height: 100%;
  font-family: "SourceSansPro", sans-serif;
  overflow-x: hidden;
  background-color: #ffffff;
}
body #main {
  background-color: #ffffff;
}

#main > section {
  transition: 0.2s transform ease, 0.2s opacity ease;
}
#main > section:not(.slided) {
  transform: translateY(150px);
  opacity: 0;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeIn {
  animation: 0.2s fadein;
}

.fadeOut {
  animation: 0.2s fadeout;
}

#imgPopup {
  z-index: 8000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
}
#imgPopup #imgContainer {
  position: relative;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 2560px;
  max-height: 2560px;
  width: 80%;
  height: auto;
  margin: auto;
}
#imgPopup #imgContainer video,
#imgPopup #imgContainer img {
  max-width: 100%;
  max-height: 70vh;
}
#imgPopup #imgContainer #imgTextWrap {
  text-align: left;
  margin: 13px;
  color: #ffffff;
  max-width: 70%;
}
#imgPopup #imgContainer #imgTextWrap #imgTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
}
#imgPopup #imgContainer #imgTextWrap #imgSubtitle {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0 !important;
}
#imgPopup #imgContainer #imgTextWrap #imgText {
  font-family: "SourceSansPro", sans-serif;
  margin-top: 26px;
}
#imgPopup .no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 749px) {
  #main > * {
    position: static !important;
    transform: none !important;
  }
  .arrow {
    display: none;
  }
  .slide {
    height: auto !important;
  }
}
.navigation {
  font-family: "SourceSansPro", sans-serif;
  margin-bottom: 51px;
}
.navigation li.active > a:after {
  padding-left: 5px;
  content: "•";
}
.navigation li.has-children.active > .expander, .navigation li.has-children.open > .expander {
  transform: rotate(90deg);
}
.navigation li.has-children > .expander {
  position: absolute;
  right: 0;
  display: inline-block;
  cursor: pointer;
  transition: 0.3s transform;
}
@media screen and (min-width: 1025px) {
  .navigation a:hover {
    opacity: 0.6 !important;
  }
}
.navigation > li.active:not(:first-child):before, .navigation > li.open:not(:first-child):before, .navigation > li:not(:last-child) > .nav:after, .navigation:after, .navigation:before {
  width: 11px;
  display: block;
  content: "";
}
.navigation:before {
  margin-bottom: 13px;
  margin-top: 11px;
  border-top: 1px solid #12120d;
}
.navigation > li.open:not(:first-child):before, .navigation > li.active:not(:first-child):before {
  padding-top: 15px;
  margin-top: 11px;
  border-top: 1px solid #12120d;
  display: inline-block;
}
.navigation > li > .nav:after, .navigation:after {
  margin-top: 11px;
  border-bottom: 1px solid #12120d;
}
.navigation > li > .nav:after {
  margin-bottom: 15px;
}
.navigation ul {
  counter-reset: item;
}
.navigation li {
  position: relative;
  counter-increment: item;
}
.navigation li:before {
  content: counters(item, ".") ". " !important;
}
.navigation li.active > .nav, .navigation li.open > .nav {
  display: block !important;
}
.navigation li a {
  display: inline-block;
  margin-bottom: 4px;
}
.navigation > li.open:before, .navigation > li.open > a, .navigation > li.active:before, .navigation > li.active > a {
  font-weight: 700;
}
.navigation > li > a {
  position: relative;
}
.navigation > li > .expander {
  display: none !important;
}
.navigation > li > .nav {
  display: none;
  list-style: none;
}
.navigation > li > .nav li:before {
  display: inline-block;
  width: 36px;
}
.navigation > li > .nav > li > .nav > li, .navigation > li > .nav > li > .nav > li > a {
  color: #727272;
}
.navigation > li > .nav .nav {
  display: none;
}
.navigation > li > .nav .open > .nav {
  display: block;
}

#side-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 320px;
  padding: 26px;
  background-color: #ffffff;
  height: 97px;
}
#side-menu #menuToggle > input {
  display: none;
}
@media screen and (max-width: 749px) {
  #side-menu #mobileMenu {
    width: 100% !important;
  }
}
@media screen and (max-width: 1024px) {
  #side-menu {
    transform: translateZ(900px);
    height: 65px;
    width: 100%;
    z-index: 200;
    right: 0;
    padding: 13px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-right: none;
  }
  #side-menu #logo {
    height: 40px;
    margin-bottom: 0 !important;
  }
  #side-menu #mobileMenu {
    top: 65px !important;
    height: calc(100vh - 65px) !important;
    padding: 13px !important;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0 0;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
  #side-menu #menuToggle {
    z-index: 1;
    position: relative;
    display: block;
    height: 24px;
    margin-top: 8px;
    -webkit-user-select: none;
    user-select: none;
  }
  #side-menu #menuToggle > input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
  }
  #side-menu #menuToggle > input:checked ~ #mobileMenu {
    transform: none;
  }
  #side-menu #menuToggle > input:checked ~ .bar1 {
    opacity: 1;
    transform: rotate(45deg) translate(6px, -8px);
  }
  #side-menu #menuToggle > input:checked ~ .bar2 {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  #side-menu #menuToggle > input:checked ~ .bar3 {
    opacity: 1;
    transform: rotate(-45deg) translate(5px, 9px);
  }
  #side-menu #menuToggle > .burger-bar {
    display: block;
    width: 33px;
    height: 2px;
    margin-bottom: 7px;
    position: relative;
    background: #12120d;
    z-index: 1;
    transform-origin: 4px 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }
  #side-menu #menuToggle > .burger-bar.bar1 {
    transform-origin: 0% 0%;
  }
  #side-menu #menuToggle > .burger-bar.bar3 {
    transform-origin: 0% 100%;
  }
}
@media screen and (min-width: 1025px) and (hover: hover) and (pointer: fine) {
  #side-menu #mobileMenu {
    opacity: 0.5;
    transition: opacity 0.5s;
  }
  #side-menu #mobileMenu:hover {
    opacity: 1;
  }
}
#side-menu #logo {
  margin-bottom: 26px;
  width: 170px;
}
#side-menu #logo img {
  max-width: 100%;
  max-height: 100%;
}
#side-menu #siteTitle h4,
#side-menu .navigation {
  font-size: 77%;
}
#side-menu #siteTitle {
  margin-bottom: 27px;
}
#side-menu #siteTitle .subtitle {
  line-height: 1.1;
  text-transform: uppercase;
}
#side-menu #mobileMenu {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: fixed;
  top: 97px;
  left: 0;
  bottom: 0;
  height: calc(100vh - 97px);
  width: 320px;
  padding: 0 26px 26px;
  background: #ffffff;
}
#side-menu #menuInfo {
  color: #727272;
  font-size: 12px;
  position: relative;
  margin-top: auto;
}
#side-menu #menuInfo #menuContact {
  color: #12120d;
}
#side-menu #menuInfo p {
  margin-bottom: 16px;
}
#side-menu #menuInfo a {
  color: inherit;
}
#side-menu #menuInfo a:hover {
  text-decoration: underline;
}

#main {
  margin-left: 320px;
}
@media screen and (max-width: 1024px) {
  #main {
    margin-left: 0;
    margin-top: 65px;
  }
}
#main h2 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
}
#main.show-gradient {
  min-height: 100vh;
  background-image: linear-gradient(to bottom, #00A9E0, #002a6f);
}

.space-1 {
  display: block;
  height: 13px;
}

.space-2 {
  display: block;
  height: 26px;
}

.space-3 {
  display: block;
  height: 39px;
}

.space-4 {
  display: block;
  height: 52px;
}

.space-5 {
  display: block;
  height: 65px;
}

@media screen and (min-width: 1025px) {
  :not(.column-50) > .textImage .text-image-background:not(.full-width) .texts,
:not(.column-50) > .textImage .text-image-top:not(.full-width) .texts {
    width: 60%;
  }
}
.textImage {
  margin-bottom: 13px;
  position: relative;
}
@media screen and (max-width: 749px) {
  .textImage .text-image-left,
.textImage .text-image-right {
    display: block !important;
    width: 100% !important;
    margin-left: 0 !important;
  }
  .textImage .image.image-left, .textImage .image.image-right {
    display: block !important;
    width: 100% !important;
    margin: 0 !important;
  }
  .textImage .image.image-left video,
.textImage .image.image-left img {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 1024px) {
  .textImage .downloads .popup {
    width: 320px !important;
  }
  .textImage .image img {
    object-fit: contain;
  }
}
.textImage .text-image-left,
.textImage .text-image-right {
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 30px);
}
.textImage .text-image-right {
  margin-left: 13px;
}
.textImage .text-image-background {
  position: absolute;
  top: 0;
}
.textImage .texts {
  padding: 26px 13px 13px;
}
.textImage .texts .text {
  margin-top: 19px;
}
.textImage .texts .text ul, .textImage .texts .text ol {
  line-height: 1.25;
  margin-bottom: 0.8em;
}
.textImage .texts .text ul li, .textImage .texts .text ol li {
  padding-left: 5px;
}
.textImage .texts .text ul {
  padding-left: 21px;
}
.textImage .texts .text ul li {
  list-style-type: disc;
}
.textImage .texts .text ul li::marker {
  font-size: 0.9em;
}
.textImage .texts .text ol {
  padding-left: 21px;
}
.textImage .texts .text ol li {
  list-style-type: decimal;
}
.textImage .image {
  position: relative;
}
.textImage .image > svg {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 23px;
  pointer-events: none;
}
.textImage .image video,
.textImage .image img {
  cursor: pointer;
  display: block;
  max-width: 100%;
}
.textImage .image video.with-shadow,
.textImage .image img.with-shadow {
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.2);
}
.textImage .image img {
  max-height: 80vh;
}
.textImage .image video {
  object-fit: contain;
  object-position: left;
}
.textImage .image.image-top video, .textImage .image.image-top img {
  cursor: default;
}
.textImage .image.image-left, .textImage .image.image-right {
  display: inline-block;
  margin: 0 13px;
  width: calc( 50% - 26px );
}
.textImage .image.image-left video,
.textImage .image.image-left img {
  margin-left: auto;
}
.textImage .downloads {
  z-index: 100;
  position: relative;
  top: -21px;
  display: inline-block;
  padding-left: 13px;
}
.textImage .downloads:hover .popup {
  visibility: visible;
  opacity: 1;
}
.textImage .downloads .popup-trigger {
  z-index: 11;
  position: relative;
  width: 23px;
  height: 23px;
}
.textImage .downloads .popup {
  z-index: 10;
  position: absolute;
  top: -17px;
  left: 0;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.2);
  background-color: #f2f2f2;
  padding: 15px 13px 26px;
  width: 405px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  transition-timing-function: ease-in-out;
}
.textImage .downloads .popup h2 {
  margin-top: 6px;
}
.textImage .downloads .popup h2,
.textImage .downloads .popup .subtitle {
  margin-left: 32px;
}
.textImage .downloads .popup .dl-list {
  margin-top: 60px;
  border-top: 1px solid #d9d9d9;
}
.textImage .downloads .popup .dl-list li {
  border-bottom: 1px solid #d9d9d9;
  padding: 13px 0;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.1;
}
.textImage .downloads .popup .dl-list li.header {
  font-weight: 700;
}
.textImage .downloads .popup .dl-list li a {
  position: relative;
  display: block;
  text-transform: uppercase;
}
.textImage .downloads .popup .dl-list li a:hover {
  font-weight: 700;
}
.textImage .downloads .popup .dl-list li a .download-title {
  width: 65%;
  padding-right: 13px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 0.8;
}
.textImage .downloads .popup .dl-list li a .download-icon {
  position: absolute;
  right: 0;
  top: -6px;
  display: inline-block;
  width: 23px;
  height: 23px;
}

.slider {
  position: relative;
  margin: 0 auto -15px;
}
@media screen and (max-width: 1024px) {
  .slider .arrow,
.slider .slide-dots {
    transform: scale(0.6) !important;
  }
  .slider .slide-dots a {
    padding: 13px !important;
  }
}
.slider .slide-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 0.7;
  font-size: 20px;
  padding-bottom: 26px;
  text-align: center;
  text-shadow: 0 0 3px #ffffff;
}
.slider .slide-dots a {
  padding: 4px;
  color: #00A9E0 !important;
}
.slider .slide-dots a.active {
  color: white !important;
}
.slider .slide-dots a:hover {
  text-decoration: none !important;
}
.slider .arrow {
  z-index: 2;
  cursor: pointer;
  height: 2rem;
  width: 1.2rem;
  position: absolute;
  top: 50%;
  margin-top: -2rem;
  background-repeat: no-repeat;
  transition: padding 0.3s;
}
.slider .arrow svg {
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.7));
}
.slider .arrow svg path {
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.2);
  stroke-width: 2px;
  fill: none;
  stroke: #ffffff;
}
.slider .arrow:hover {
  padding: 0.2rem;
}
.slider #arrow-left {
  left: 0;
  margin-left: 1rem;
}
.slider #arrow-right {
  right: 0;
  margin-right: 1rem;
}
.slider .slider-area {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.slider .slider-area::-webkit-scrollbar {
  width: 0;
}
.slider .slider-area .slide {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100%;
  height: 80vh;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  margin: 0 1px;
}

.columns {
  margin-top: 26px;
}
@media screen and (max-width: 749px) {
  .columns .columns-wrap {
    display: block !important;
    margin-right: 0 !important;
  }
  .columns .columns-wrap .column-50 {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .columns .columns-wrap .column-50 .textImage .image {
    margin-left: 13px;
    margin-right: 13px;
  }
}
@media screen and (min-width: 1025px) {
  .columns .columns-wrap .column-50:first-child .textImage .image img {
    margin-left: auto;
  }
}
.columns .columns-wrap {
  display: flex;
  justify-content: space-between;
  margin-right: 26px;
}
.columns .columns-wrap .column-50 {
  width: 50%;
  margin-left: 13px;
}
.columns .columns-wrap .column-50 .textImage .image-top video,
.columns .columns-wrap .column-50 .textImage .image-top img {
  cursor: pointer !important;
}

.pre-login-text {
  margin-top: 37px;
}

.login {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 51px;
}
.login .login-forward-link:hover {
  text-decoration: underline;
}
.login .login-trigger {
  font-weight: 700;
  margin-bottom: 13px;
}
.login .submit {
  font-weight: 700;
  padding: 4px;
  text-align: left;
  margin-top: 15px;
  display: inline-block;
  padding-right: 50px;
  width: auto;
  text-transform: uppercase;
}
.login .submit:hover {
  text-decoration: underline;
}
.login .loginform input {
  display: block;
  margin-bottom: 13px;
}
.login .loginform input[type=text], .login .loginform input[type=password] {
  padding: 5px 3px;
  width: 80%;
}
.login .loginform label {
  margin-top: 13px;
}

#main > .media-wrapper {
  position: relative !important;
  overflow: hidden;
}
#main > .media-wrapper .media-over {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
#main > .media-wrapper .media-over img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  transition: opacity 200ms;
}
#main > .media-wrapper .media-over button {
  display: block;
  position: absolute;
  border-radius: 50%;
  width: 74px;
  height: 74px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: transparent;
  transition: background-color 200ms ease-in-out, opacity 200ms;
}
#main > .media-wrapper .media-over button:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
#main > .media-wrapper .media-over button.play {
  pointer-events: auto;
  border: 3px solid #12120d;
}
#main > .media-wrapper .media-over button.play span {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
#main > .media-wrapper .media-over button.play span:before, #main > .media-wrapper .media-over button.play span:after {
  content: "";
  position: absolute;
  width: 50%;
  right: 25%;
  height: 0;
  border-bottom: 3px solid #12120d;
  top: 50%;
  transform-origin: right center;
}
#main > .media-wrapper .media-over button.play span:before {
  transform: translateY(-50%) rotateZ(-35deg);
}
#main > .media-wrapper .media-over button.play span:after {
  transform: translateY(-50%) rotateZ(35deg);
}
#main > .media-wrapper .media-over button.sound {
  opacity: 0;
  pointer-events: none;
  border-radius: 50%;
}
#main > .media-wrapper .media-over button.sound g.x {
  transition: opacity 200ms;
}
#main > .media-wrapper .media-over button.sound.mute g.x {
  opacity: 0;
}
#main > .media-wrapper.playing img {
  opacity: 0;
}
#main > .media-wrapper.playing button.play {
  opacity: 0;
  pointer-events: none;
}
#main > .media-wrapper.autoplay button.sound {
  opacity: 1;
  pointer-events: auto;
}
#main > .media-wrapper.autoplay.unmuted button.sound {
  opacity: 0;
  pointer-events: none;
}

.code .hljs-copy-button {
  background-color: #12120d !important;
}
.code .hljs-copy-button[data-copied=true] {
  padding: 0 10px;
}
.code .tab-wrap {
  border-radius: 6px;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  list-style: none;
}
.code .tab {
  display: none;
}
.code .tab__content {
  background-color: transparent;
  position: absolute;
  width: 100%;
  z-index: -1;
  opacity: 0;
  left: 0;
  transform: translateY(-3px);
  border-radius: 6px;
  transition: 0.5s opacity ease-in, 0.2s transform ease;
}
.code .tab:checked:nth-of-type(1) ~ .tab__content:nth-of-type(1) {
  opacity: 1;
  position: relative;
  transform: translateY(0px);
  top: 0;
  z-index: 100;
  text-shadow: 0 0 0;
}
.code .tab:checked:nth-of-type(2) ~ .tab__content:nth-of-type(2) {
  opacity: 1;
  position: relative;
  transform: translateY(0px);
  top: 0;
  z-index: 100;
  text-shadow: 0 0 0;
}
.code .tab:checked:nth-of-type(3) ~ .tab__content:nth-of-type(3) {
  opacity: 1;
  position: relative;
  transform: translateY(0px);
  top: 0;
  z-index: 100;
  text-shadow: 0 0 0;
}
.code .tab:checked:nth-of-type(4) ~ .tab__content:nth-of-type(4) {
  opacity: 1;
  position: relative;
  transform: translateY(0px);
  top: 0;
  z-index: 100;
  text-shadow: 0 0 0;
}
.code .tab:checked:nth-of-type(5) ~ .tab__content:nth-of-type(5) {
  opacity: 1;
  position: relative;
  transform: translateY(0px);
  top: 0;
  z-index: 100;
  text-shadow: 0 0 0;
}
.code .tab:first-of-type:not(:last-of-type) + label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.code .tab:not(:first-of-type):not(:last-of-type) + label {
  border-radius: 0;
}
.code .tab:last-of-type:not(:first-of-type) + label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.code .tab:checked + label {
  background-color: #fff;
  box-shadow: 0 -1px 0 #fff inset;
  cursor: default;
}
.code .tab:checked + label:hover {
  box-shadow: 0 -1px 0 #fff inset;
  background-color: #fff;
}
.code .tab + label {
  width: 100%;
  box-shadow: 0 -1px 0 #eee inset;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #333;
  flex-grow: 3;
  text-align: center;
  background-color: #f2f2f2;
  user-select: none;
  text-align: center;
  transition: 0.3s background-color ease, 0.3s box-shadow ease;
  height: 50px;
  box-sizing: border-box;
  padding: 15px;
}
.code .tab + label:hover {
  background-color: #f9f9f9;
  box-shadow: 0 1px 0 #f4f4f4 inset;
}
@media screen and (min-width: 1025px) {
  .code .tab + label {
    width: auto;
  }
}

body#standard-sukoa {
  font-family: "Montserrat";
  background-color: #00e1ff;
}
body#standard-sukoa #main {
  background-color: #00e1ff;
}
body#standard-sukoa #logo {
  height: 39px;
}
body#standard-sukoa h1, body#standard-sukoa h2, body#standard-sukoa h3, body#standard-sukoa h4,
body#standard-sukoa .subtitle {
  font-family: "Montserrat";
}
body#standard-sukoa .loginform input,
body#standard-sukoa .login {
  font-family: "Montserrat";
}
body#standard-sukoa .navigation {
  font-family: "Montserrat";
}
body#standard-sukoa #side-menu #mobileMenu {
  opacity: 1;
}

#imgPopup.standard-sukoa #imgContainer #imgTextWrap #imgSubtitle {
  font-family: "Montserrat";
}
#imgPopup.standard-sukoa #imgContainer #imgTextWrap #imgText {
  font-family: "Montserrat";
}

@font-face {
  font-family: "Inter";
  src: url(../fonts/Inter-Light.ttf) format("truetype");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Inter";
  src: url(../fonts/Inter-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Inter";
  src: url(../fonts/Inter-SemiBold.ttf) format("truetype");
  font-style: normal;
  font-weight: 700;
}
body#standard-sva {
  font-family: "Inter";
}
body#standard-sva #main.show-gradient {
  background-image: linear-gradient(to bottom, #0087A0, #0087A0);
}
body#standard-sva h1, body#standard-sva h2, body#standard-sva h3, body#standard-sva h4,
body#standard-sva .subtitle {
  font-family: "Inter";
}
body#standard-sva h2 {
  line-height: 1.3;
  font-size: 18px;
  letter-spacing: 0.2px;
}
body#standard-sva .subtitle,
body#standard-sva .text {
  font-weight: 100;
}
body#standard-sva .text {
  line-height: 1.3;
}
body#standard-sva .text a {
  text-decoration: underline;
}
body#standard-sva .text a[href*="dam/jcr:"] {
  text-decoration: none;
}
body#standard-sva .text a[href*="dam/jcr:"]:before {
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJFYmVuZV8xIiBkYXRhLW5hbWU9IkViZW5lIDEiIHZpZXdCb3g9IjAgMCA0Mi41MiA0Mi41MiI+PHBhdGggZD0iTTIxLjI2IDQyLjUyQzkuNTQgNDIuNTIgMCAzMi45OCAwIDIxLjI2UzkuNTQgMCAyMS4yNiAwczIxLjI2IDkuNTQgMjEuMjYgMjEuMjYtOS41NCAyMS4yNi0yMS4yNiAyMS4yNlptMC00MS4yN2MtMTEuMDMgMC0yMC4wMSA4Ljk4LTIwLjAxIDIwLjAxczguOTggMjAuMDEgMjAuMDEgMjAuMDEgMjAuMDEtOC45OCAyMC4wMS0yMC4wMVMzMi4yOSAxLjI1IDIxLjI2IDEuMjVaIiBzdHlsZT0ic3Ryb2tlLXdpZHRoOjA7ZmlsbDojMDA4N2EwOyIvPjxwYXRoIGQ9Ik0zMC45MSAyNi41OHYyLjEzYzAgMS4yMi0uOTkgMi4yMS0yLjIxIDIuMjFIMTMuODJhMi4yMSAyLjIxIDAgMCAxLTIuMjEtMi4yMXYtMi4xM2MwLS45LTEuMjUtLjktMS4yNSAwdjIuMTNjMCAxLjkxIDEuNTUgMy40NiAzLjQ2IDMuNDZIMjguN2MxLjkxIDAgMy40Ni0xLjU1IDMuNDYtMy40NnYtMi4xM2MwLS45LTEuMjUtLjktMS4yNSAwWiIgc3R5bGU9InN0cm9rZS13aWR0aDowO2ZpbGw6I2YwODIyOCIvPjxwYXRoIGQ9Ik0yMS4yNiAyOC41M2MuMiAwIC40LS4xLjUxLS4yN0wzMCAxNi40NWEuNjMyLjYzMiAwIDAgMC0uNTEtLjk5aC00LjY0VjcuN2MwLS4zNS0uMjgtLjYzLS42Mi0uNjNzLS42My4yOC0uNjMuNjJ2OC4zOWMwIC4zNS4yNy42My42Mi42M2g0LjA3TDIxLjI1IDI2LjhsLTcuMDQtMTAuMDloNC4wN2MuMzQgMCAuNjItLjI4LjYyLS42MlY3LjdhLjYyNS42MjUgMCAwIDAtMS4yNSAwdjcuNzZoLTQuNjRhLjYxLjYxIDAgMCAwLS41NS4zNGMtLjExLjIxLS4wOS40Ni4wNC42NWw4LjIzIDExLjgxYy4xMi4xNy4zMS4yNy41MS4yN1oiIHN0eWxlPSJzdHJva2Utd2lkdGg6MDtmaWxsOiMwMDg3YTA7Ii8+PC9zdmc+Cg==");
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-right: 10px;
  margin-top: -2px;
  vertical-align: middle;
}
body#standard-sva .text ul li,
body#standard-sva .text ol li {
  margin-bottom: 4px;
}
body#standard-sva .text ul {
  padding-left: 11px;
}
body#standard-sva .text ul li {
  list-style: none;
  padding-left: 0;
}
body#standard-sva .text ul li:before {
  content: "• ";
  font-size: 14px;
  vertical-align: top;
  line-height: 20px;
  margin-left: -12px;
}
body#standard-sva #side-menu #logo {
  height: 46px;
}
body#standard-sva #side-menu #mobileMenu {
  opacity: 1;
}
body#standard-sva #side-menu #mobileMenu #siteTitle h4 {
  font-weight: normal;
  text-transform: none;
}
body#standard-sva #side-menu #mobileMenu .loginform input,
body#standard-sva #side-menu #mobileMenu .login {
  font-family: "Inter";
}
body#standard-sva #side-menu #mobileMenu .navigation {
  font-family: "Inter";
}
body#standard-sva #side-menu #mobileMenu .navigation > li > ul > li:before, body#standard-sva #side-menu #mobileMenu .navigation > li > ul > li > ul > li:before {
  content: counters(item, ".") " " !important;
}
body#standard-sva #main > section {
  transition: none !important;
}
body#standard-sva #main > section:not(.slided) {
  transform: none !important;
  opacity: 1 !important;
}

#imgPopup.standard-sva #imgContainer #imgTextWrap #imgTitle,
#imgPopup.standard-sva #imgContainer #imgTextWrap #imgSubtitle,
#imgPopup.standard-sva #imgContainer #imgTextWrap #imgText {
  font-family: "Inter";
}