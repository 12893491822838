@charset "UTF-8";

.pre-login-text{
  margin-top: px($menu-spacing);
}
.login{
  font-family: $secondFontFace;
  margin-bottom: px($menu-spacing + 14);

  .login-forward-link {
    &:hover{
      text-decoration: underline;
    }
  }

  .login-trigger {
    font-weight: 700;
    margin-bottom: px($box-spacing);
  }
  .submit {
    font-weight: 700;
    padding: 4px;
    text-align: left;
    margin-top: 15px;
    display: inline-block;
    padding-right: 50px;
    width: auto;
    text-transform: uppercase;
    &:hover {
      text-decoration: underline;
    }
  }

  .loginform{
    input {
      display: block;
      margin-bottom: px($box-spacing);
      &[type="text"],
      &[type="password"] {
        padding: 5px 3px;
        width: 80%;
      }
    }
    label {
      margin-top: px($box-spacing);
    }
  }
}
