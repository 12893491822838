@charset "UTF-8";

.navigation {
  font-family: $mainFontFace;
  margin-bottom: px($menu-spacing + 14);

  li.active >a:after {
    padding-left: 5px;
    content: '•';
  }
  li.has-children{
    &.active>.expander,
    &.open>.expander{
      transform: rotate(90deg);
    }
    &>.expander {
      position: absolute;
      right: 0;
      display: inline-block;
      cursor: pointer;
      transition: .3s transform;
    }
  }

  @include lView(){
    //limit hover states to above touch (so they dont influence the mobile expanders)
    a:hover {opacity: .6 !important;}
  }

  //horizontal seperation lines
  & > li.active:not(:first-child):before,
  & > li.open:not(:first-child):before,
  & > li:not(:last-child) > .nav:after,
  &:after,
  &:before{
    width: 11px;
    display: block;
    content: '';
  }

  //& > li.active:before,
  &:before{
    margin-bottom: 13px;
    margin-top: 11px;
    border-top: 1px solid $black;
  }
  & > li.open:not(:first-child):before,
  & > li.active:not(:first-child):before {
    padding-top: 15px;
    margin-top: 11px;
    border-top: 1px solid $black;
    display: inline-block;
  }

  & > li > .nav:after,
  &:after{
    margin-top: 11px;
    border-bottom: 1px solid $black;
  }
  & > li > .nav:after {
    margin-bottom: 15px;
  }

  //numbering 1. > 1.1. / 2. > 2.1.
  ul{
    counter-reset: item;
  }
  li {
    position: relative;
    counter-increment: item;

    &:before {
      content: counters(item, ".") ". " !important;
    }
    &.active > .nav,
    &.open > .nav {
      display: block !important;
    }

    a {
      display: inline-block;
      margin-bottom: 4px;
    }
  }

  //first level list nodes and styling dependent on level
  & > li {

    &.open,
    &.active {
      &:before,
      & > a {
        font-weight: 700;
      }
    }
    & > a {
      position: relative;
    }
    & > .expander {
      display: none !important;
    }
    & > .nav{
      display: none;
      list-style: none;
      li {
        &:before {
          display: inline-block;
          width: 36px;
        }
      }
      & > li {
        & > .nav > li {
          &,
          &>a{
            color: $grey;
          }
        }
      }
      .nav{ display: none; }
      .open > .nav{ display: block; }
    }
  }
}
