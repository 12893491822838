@charset "UTF-8";

$sva_mainFontFace: "Inter";
$sva_secondFontFace: "Inter";
$sva_blue: #0087A0;
$sva_bg: $white;

@font-face {
  font-family: 'Inter';
  src: url(../fonts/Inter-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 100
}
@font-face {
  font-family: 'Inter';
  src: url(../fonts/Inter-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 300
}
@font-face {
  font-family: 'Inter';
  src: url(../fonts/Inter-SemiBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700
}

body#standard-sva {

  font-family: $sva_mainFontFace;

  #main {
    &.show-gradient {
      background-image: linear-gradient(to bottom, $sva_blue, $sva_blue);
    }
  }

  h1,h2,h3,h4,
  .subtitle {
    font-family: $sva_mainFontFace;
  }

  h2 {
    line-height: 1.3;
    font-size: 18px;
    letter-spacing: 0.2px;
  }

  .subtitle,
  .text {
    font-weight: 100;
  }

  .text {
    line-height: 1.3;

    a {
      text-decoration: underline;

      &[href*="dam/jcr:"] {
        text-decoration: none;

        &:before {
          content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJFYmVuZV8xIiBkYXRhLW5hbWU9IkViZW5lIDEiIHZpZXdCb3g9IjAgMCA0Mi41MiA0Mi41MiI+PHBhdGggZD0iTTIxLjI2IDQyLjUyQzkuNTQgNDIuNTIgMCAzMi45OCAwIDIxLjI2UzkuNTQgMCAyMS4yNiAwczIxLjI2IDkuNTQgMjEuMjYgMjEuMjYtOS41NCAyMS4yNi0yMS4yNiAyMS4yNlptMC00MS4yN2MtMTEuMDMgMC0yMC4wMSA4Ljk4LTIwLjAxIDIwLjAxczguOTggMjAuMDEgMjAuMDEgMjAuMDEgMjAuMDEtOC45OCAyMC4wMS0yMC4wMVMzMi4yOSAxLjI1IDIxLjI2IDEuMjVaIiBzdHlsZT0ic3Ryb2tlLXdpZHRoOjA7ZmlsbDojMDA4N2EwOyIvPjxwYXRoIGQ9Ik0zMC45MSAyNi41OHYyLjEzYzAgMS4yMi0uOTkgMi4yMS0yLjIxIDIuMjFIMTMuODJhMi4yMSAyLjIxIDAgMCAxLTIuMjEtMi4yMXYtMi4xM2MwLS45LTEuMjUtLjktMS4yNSAwdjIuMTNjMCAxLjkxIDEuNTUgMy40NiAzLjQ2IDMuNDZIMjguN2MxLjkxIDAgMy40Ni0xLjU1IDMuNDYtMy40NnYtMi4xM2MwLS45LTEuMjUtLjktMS4yNSAwWiIgc3R5bGU9InN0cm9rZS13aWR0aDowO2ZpbGw6I2YwODIyOCIvPjxwYXRoIGQ9Ik0yMS4yNiAyOC41M2MuMiAwIC40LS4xLjUxLS4yN0wzMCAxNi40NWEuNjMyLjYzMiAwIDAgMC0uNTEtLjk5aC00LjY0VjcuN2MwLS4zNS0uMjgtLjYzLS42Mi0uNjNzLS42My4yOC0uNjMuNjJ2OC4zOWMwIC4zNS4yNy42My42Mi42M2g0LjA3TDIxLjI1IDI2LjhsLTcuMDQtMTAuMDloNC4wN2MuMzQgMCAuNjItLjI4LjYyLS42MlY3LjdhLjYyNS42MjUgMCAwIDAtMS4yNSAwdjcuNzZoLTQuNjRhLjYxLjYxIDAgMCAwLS41NS4zNGMtLjExLjIxLS4wOS40Ni4wNC42NWw4LjIzIDExLjgxYy4xMi4xNy4zMS4yNy41MS4yN1oiIHN0eWxlPSJzdHJva2Utd2lkdGg6MDtmaWxsOiMwMDg3YTA7Ii8+PC9zdmc+Cg==");
          width: 28px;
          height: 28px;
          display: inline-block;
          margin-right: 10px;
          margin-top: -2px;
          vertical-align: middle;
        }
      }
    }

    ul,
    ol {
      li {
        margin-bottom: 4px;
      }
    }

    ul {
      padding-left: 11px;

      li {
        list-style: none;
        padding-left: 0;
      }

      li:before {
        content:"• ";
        font-size: 14px;
        vertical-align: top;
        line-height: 20px;
        margin-left: -12px;
      }
    }
  }

  #side-menu {
    #logo {
      height: 46px;
    }
    #mobileMenu {
      opacity: 1;

      #siteTitle {
        h4 {
          font-weight: normal;
          text-transform: none;
        }
      }

      .loginform input,
      .login {
        font-family: $sva_mainFontFace;
      }

      .navigation {
        font-family: $sva_mainFontFace;

        //second and third level nav should not have a ending dot, so 1.1. > 1.1
        & > li > ul > li,
        & > li > ul > li > ul > li {
          &:before {
            content: counters(item, ".") " " !important;
          }
        }
      }
    }
  }

  //DO NOT slide all components in on scroll
  #main {
    & > section {
      transition: none !important;
      &:not(.slided) {
        transform: none !important;
        opacity: 1 !important;
      }
    }
  }
}

#imgPopup.standard-sva {
  #imgContainer{
    #imgTextWrap {
      #imgTitle,
      #imgSubtitle,
      #imgText {
        font-family: $sva_secondFontFace;
      }
    }
  }
}
