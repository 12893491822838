@charset "UTF-8";

.columns{
  margin-top: px($base-padding);

  @include sView(){
    .columns-wrap{
      display: block !important;
      margin-right: 0 !important;

      .column-50{
        width: 100% !important;
        margin-left: 0 !important;

        .textImage{
          .image{
            margin-left: px($box-spacing);
            margin-right: px($box-spacing);
          }
        }
      }
    }
  }
  @include mlView(){
    .columns-wrap{
      .column-50:first-child {

        .textImage{
          .image{
            img {
              margin-left: auto;
            }
          }
        }
      }
    }
  }

  .columns-wrap{
    display: flex;
    justify-content: space-between;
    margin-right: px($box-spacing * 2);

    .column-50{
      width: 50%;
      margin-left: px($box-spacing);

      .textImage{
        .image-top video,
        .image-top img {
          cursor: pointer !important;
        }
      }
    }
  }
}
